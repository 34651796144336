.NavLink {
  text-decoration: none;
  color: rgba(51, 51, 51, 0.647);
  padding: 3px 40%;
  border-radius: 15px;
  font-family: "Kanit";
  display: flex;
  align-items: center;
  gap: 5px;
}

@media (max-width: 767.98px) {
  .nav-bar-link {
    font-size: 10px !important;
  }
}

.nav-bar-link {
  text-decoration: none;
  color: rgba(51, 51, 51, 0.647);
  font-family: "Kanit";
}
.nav-bar-active {
  color: #2b0763;
}
.nav-active {
  color: #000000;
}
.NavLink:hover {
  background: rgba(19, 13, 75, 0.647);
}
