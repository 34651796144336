body {
  font-family: "Kanit";
}

.swiper-wrapper {
  text-align: center;
}

.swiper-slide {
  height: 322px !important;
  border-radius: 22px !important;
}

.swiper-slide img {
  height: 292px !important;
  width: 100% !important;
  border-radius: 22px !important;
  /* object-fit: cover; */
}

@media (max-width: 767.98px) {
  .swiper-slide {
    height: 214px !important;
  }
  .swiper-slide img {
    height: 181px !important;
  }
}

.swiper-pagination {
  position: absolute !important;
  bottom: 0 !important;
  left: 0 !important;
}

.swiper-pagination-bullet {
  background: #4d4d4d !important;
  width: 15px !important;
  border-radius: 12px !important;
}

.swiper-pagination-bullet-active {
  background: #00e3c9 !important;
  width: 30px !important;
  border-radius: 12px !important;
}

.img-fluid {
  height: auto;
  width: auto;
}
